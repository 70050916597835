import React, { useContext, useEffect, useState, useRef, useMemo } from "react"
import s from "../ModalFilters.module.scss"
import Image from "next/dist/client/image"
import IndividualTraits from "./individual-traits"
import { GoPlus } from "react-icons/go"
import { FcMinus } from "react-icons/fc"

const MarketplaceTraitFilter = props => {
	const {
		sub_cats,
		appliedFilters,
		setAppliedFilters,
		isDarkModeEnabled,
		appliedFiltersMobile,
		setAppliedFiltersMobile,
		isMobile,
        isMarketplace
	} = props
	
	const [isTraitsOpen, setTraitsOpen] = useState(false)

	const checkCategoryOpens = category => {
		const isOpen = appliedFilters
			? appliedFilters?.some(f => f.category === category)
			: false
		setTraitsOpen(isOpen)
	}

	useEffect(() => {
		checkCategoryOpens(props.category)
	}, [])

	useEffect(() => {}, [appliedFilters, appliedFiltersMobile])

	const [elementPosition, setElementPosition] = useState({ x: 0, y: 0 })
	const boundingRef = useRef()
	// console.log('suba_catesssss!----->', sub_cats)
	return (
		<>
			<>
				<div
					className={`${s.modal__filter_outer_container}`}
					style={props.style}
				>
					<h3 className={`${s.modal__flow_root}`}>
						<button
							onClick={() => setTraitsOpen(!isTraitsOpen)}
							type={`button`}
							className={`${s.modal__filter_inner_container} ${
								isDarkModeEnabled ? s.modal__dark : s.modal__light
							}`}
						>
							<span
								className={`${s.modal__filter_span} ${
									props.category === "Special" ? s.modal__special : ""
								}`}
							>
								<span className={`${s.modal__filter_text_marketplace}`}>
									{props.category}
								</span>
							</span>
							<span className={`ml-6 flex items-center`}>
								{!isTraitsOpen ? (
									<GoPlus className={s.open_close_marketplace} />
								) : (
									<FcMinus className={s.open_close_marketplace} />
								)}
							</span>
						</button>
					</h3>
				</div>
				<div ref={boundingRef} className={s.modal__trait_outer_container}>
					{isTraitsOpen &&
						sub_cats.map((el, idx) => {
							const animation = `${s.fadein} 0.5s linear ${
								idx * 0.01
							}s backwards`
							return (
								<IndividualTraits
									key={props.category + ": " + el.name}
									category={props.category}
									item={el}
									appliedFilters={appliedFilters}
									setAppliedFilters={setAppliedFilters}
									categoryRef={boundingRef}
									categoryPosition={elementPosition.y}
									appliedFiltersMobile={appliedFiltersMobile}
									isMobile={isMobile}
									isMarketplace={isMarketplace}
									onChange={item => {
										const obj = {
											key: item.key,
											id: item.id,
											category: item.category,
											value: item.value
										}
										if (item.checked) {
											if (!isMobile) {
												console.log("applied filters")
												console.log(appliedFilters)
												setAppliedFilters(prevState => {
													return [...prevState, obj]
												})
											} else {
												setAppliedFiltersMobile(prevState => [
													...prevState,
													obj
												])
											}
										} else {
											const removeTrait = applied => {
												return applied.filter(t => t.key !== obj.key)
											}

											if (!isMobile) {
												setAppliedFilters(prevState => removeTrait(prevState))
											} else {
												setAppliedFiltersMobile(prevState =>
													removeTrait(prevState)
												)
											}
										}
									}}
									style={{
										animation: animation,
										WebkitAnimation: animation,
										MozAnimation: animation
									}}
								/>
							)
						})}
				</div>
			</>
		</>
	)
}

export default MarketplaceTraitFilter
