import React, { useEffect, useState, ChangeEvent } from "react"
import s from "../ModalFilters.module.scss"

const IndividualTraits = props => {
	const { appliedFilters, category, trait, item, appliedFiltersMobile, isMobile ,isMarketplace } =
		props
	const [isChecked, setIsChecked] = useState(false)

	useEffect(() => {
		let applied = !isMobile ? appliedFilters : appliedFiltersMobile
		// console.log('applied---->', applied)
		if (
			!isMarketplace ?
				applied?.some(f => f.category === category && f.value === trait.value) :
				applied?.some(f => f.category === category && f.value === item.name)
		) {
			setIsChecked(true)
		} else {
			setIsChecked(false)
		}
	}, [appliedFilters, appliedFiltersMobile])

	const parsePercentage = number => {
		number = number / 8192
		// Convert to percentage
		number = number * 100

		if (number < 1) {
			let final = Math.ceil(number * 100) / 100
			if (final >= 1) {
				return Math.round(number)
			}
			return final.toString().substring(1, 4)
		}

		return Math.round(number)
	}

	const traitId = !isMarketplace && (props.category + ": " + props.trait.value)
	return (
		<div className={`${s.modal__filter_drop}`} style={props.style}>
			<div className={`${s.modal__filter_drop_row}`}>
				<>
					<div className={s.modal__checkbox_parent}>
						<input
							id={!isMarketplace ? traitId : item.name}
							type={`checkbox`}
							className={`${isMarketplace ? s.modal__checkbox_marketplace : s.modal__checkbox}`}
							name={!isMarketplace ? props.trait.value : item.name}
							checked={isChecked}
							onChangeCapture={(e: ChangeEvent<HTMLInputElement>) => {
								setIsChecked(e.target.checked)
								props.onChange({
									key: !isMarketplace ? (props.category + ": " + props.trait.value) : (props.category + ": " + item.name),
									id: isMarketplace ? item.id : '',
									category: props.category,
									value: !isMarketplace ? props.trait.value : item.name,
									checked: e.target.checked
								})
							}}
						/>
					</div>
					<label htmlFor={!isMarketplace ? traitId : item.name} className={`${isMarketplace ? s.modal__filter_drop_marketplace : s.modal__filter_drop_name}`}>
						{!isMarketplace ? props.trait.value : item.name}
					</label>
				</>
			</div>
		</div>
	)
}

export default IndividualTraits
