import React, { useContext, useEffect, useState, useRef, useMemo } from "react"
import s from "../ModalFilters.module.scss"
import Select from "react-select"
import TraitFilter from "./trait-filter"
import Image from "next/image"
import MarketplaceTraitFilter from "./marketplace-trait-filter"

const FiltersView = ({
	handleApplyFilters,
	filters,
	colors,
	appliedColor,
	sortBy,
	setSortBy,
	appliedFilters,
	setAppliedFilters,
	appliedFiltersMobile,
	setAppliedFiltersMobile,
	isMobile,
	onChangeSearchByTokenId,
	setSearchedToken,
	searchedToken,
	setSearchByTokenIdMobile,
	searchByTokenIdMobile,
	resetFilters,
	isMarketplace,
}) => {
	const sortOptions = [
		{ value: "Most Rare", label: "Fashion Rank (High to Low)" },
		{ value: "Least Rare", label: " Fashion Rank (Low to High)" },
		{ value: "token_id", label: "HAPE Prime ID" },
	]

	const sortStyles = {
		menu: (styles) => ({
			...styles,
			backgroundColor: "transparent",
			color: "white",
		}),
		control: (styles) => ({
			...styles,
			backgroundColor: "transparent",
			color: "white",
			borderColor: "rgba(255, 255, 255, 0.2)",
		}),
		option: (styles) => {
			return {
				...styles,
				backgroundColor: "#525252",
				color: "white",
			}
		},
		singleValue: (styles) => ({
			...styles,
			color: "white",
		}),
	}

	// console.log('filters!!!!!!@@@----->', filters)
	// console.log('colors in filter view!!!!!!@@@----->', colors)
	// const splitColors = colors.map(el => el.split(',')).flat();
	// console.log('flat colorArr--->', splitColors);
	// const uniqueColorArr = [...new Set(splitColors)];
	// console.log('unique color arr---->', uniqueColorArr)

	return (
		<>
			<form onSubmit={handleApplyFilters} style={{ height: "100%" }}>
				<div className={`${s.modal__filter_container}`}>
					<div className={s.search_parent}>
						{isMarketplace ? (
							<></>
						) : (
							// <div className={s.flexWrap}>
							//   <input
							//     className={`${s.search_by_item}`}
							//     type={"search"}
							//     value={searchByTokenIdMobile}
							//     onChange={e => {
							//       e.preventDefault()
							//       if (
							//         e.target.value === ""
							//         // parseInt(e.target.value) < 1 ||
							//         // parseInt(e.target.value) > 8192
							//       ) {
							//         setSearchedToken("")
							//         setSearchByTokenIdMobile("")
							//       } else {
							//         if (!isMobile) {
							//           setSearchedToken(e.target.value)
							//           setSearchByTokenIdMobile(e.target.value)
							//         } else {
							//           console.log(e.target.value)
							//           setSearchByTokenIdMobile(e.target.value)
							//         }
							//       }
							//     }}
							//     // max={8888}
							//     placeholder={"Search by Item Name or Category"}
							//   />
							//   <img
							//     className={s.searchIcon}
							//     src={`/static/img/marketplace/search_icon.svg`}
							//   />
							// </div>
							<input
								onKeyPress={(e) => {
									e.key === "Enter" && e.preventDefault()
								}}
								className={`${s.search_by_token}`}
								type={"search"}
								value={searchByTokenIdMobile}
								max={8888}
								onChange={(e) => {
									e.preventDefault()
									if (
										e.target.value === "" ||
										parseInt(e.target.value) < 1 ||
										parseInt(e.target.value) > 8192
									) {
										setSearchedToken("")
										setSearchByTokenIdMobile("")
									} else {
										if (!isMobile) {
											setSearchedToken(e.target.value)
											setSearchByTokenIdMobile(e.target.value)
										} else {
											console.log(e.target.value)
											setSearchByTokenIdMobile(e.target.value)
										}
									}
								}}
								placeholder={"Search by HAPE ID #"}
							/>
						)}
					</div>

					{!isMarketplace && (
						<div className={s.sort_parent}>
							{/* <Select
								className={s.modal__sort_by}
								options={sortOptions}
								styles={sortStyles}
								placeholder={"Sort by..."}
								isSearchable={false}
								onChange={(e) => {
									setSortBy(e)
								}}
							/> */}
						</div>
					)}

					<div className={s.modal__filter_parent_container}>
						{isMarketplace && (
							<div className={s.modal__filter_title}>Apparel</div>
						)}
						<div>
							{filters
								?.sort((a, b) =>
									a.category
										? a.category < b.category
											? -1
											: 1
										: a.name < b.name
											? -1
											: 1,
								)
								?.map((filter, idx) => {
									return (
										<>
											{isMarketplace ? (
												<MarketplaceTraitFilter
													key={filter.name}
													category={filter.name}
													sub_cats={filter.sub_categories}
													appliedFilters={appliedFilters}
													setAppliedFilters={setAppliedFilters}
													appliedFiltersMobile={appliedFiltersMobile}
													setAppliedFiltersMobile={setAppliedFiltersMobile}
													isMobile={isMobile}
													isMarketplace={isMarketplace}
												/>
											) : (
												<>
													{/* <TraitFilter
													key={filter.category}
													category={filter.category}
													traits={filter.traits}
													appliedFilters={appliedFilters}
													setAppliedFilters={setAppliedFilters}
													appliedFiltersMobile={appliedFiltersMobile}
													setAppliedFiltersMobile={setAppliedFiltersMobile}
													isMobile={isMobile}
												/> */}
												</>
											)}
										</>
									)
								})}
						</div>
						{/* Colors Filter */}
						{/* {isMarketplace &&
              <>
                <hr />
                <div className={s.modal__color_title}>Colour</div>
                <div className={s.modal__color_tiles}>
                  {colors.length > 0 && colors.map(el => (
                    <>
                      {(el.match(/,/g)?.length > 0) ?
                        <>
                          <div
                            onClick={() => setAppliedColor(el)}
                            style={{
                              width: 36,
                              height: 34,
                              margin: 5,
                              borderRadius: 8,
                              cursor: 'pointer',
                              background: `linear-gradient(to right, ${el.split(',')[0]} 50%, ${el.split(',')[1]} 50%)`
                            }}>
                          </div>
                        </>
                        : <div
                          onClick={() => setAppliedColor(el)}
                          style={{
                            width: 36,
                            height: 34,
                            margin: 5,
                            borderRadius: 8,
                            cursor: 'pointer',
                            background: el
                          }}>
                        </div>
                      }
                    </>
                  ))}
                </div>
              </>
            } */}
					</div>
					{!isMobile && (
						<div
							className={`${
								isMarketplace ? s.clearContainer : s.clear_container
							}`}
						>
							<button
								className={`${
									isMarketplace ? s.resetMarketplace : s.reset_web
								}`}
								onClick={resetFilters}
							>
								Clear All
							</button>
						</div>
					)}
				</div>
			</form>
		</>
	)
}

export default FiltersView
