import React, { useContext, useEffect, useState, useRef, useMemo } from "react"
import { ModalContext } from "../../contexts/ModalContext"
import s from "./ModalFilters.module.scss"
import useDidMountEffect from "../../hooks/useDidMountEffect"
import { IoClose } from "react-icons/io5"
import { DarkModeContext } from "../../contexts/DarkModeContext"
import FiltersView from "./components/filters-view"
import Image from "next/image"

const ModalFilters = props => {
	const {
		isMobile,
		onClose,
		isOpen,
		appliedFilters,
		setAppliedFilters,
		filters,
		colors,
		appliedColor,
		setSearchedToken,
		onChangeSearchByTokenId,
		searchedToken,
		isMarketplace,
		handleFilterCollapse
	} = props

	const { appliedSortBy, setAppliedSortBy } = useContext(ModalContext)
	const { isDarkModeEnabled } = useContext(DarkModeContext)

	const [searchByTokenIdMobile, setSearchByTokenIdMobile] = useState("")
	const [sortBy, setSortBy] = useState(null)
	const [fadeStyle, setFadeStyle] = useState(`${s.modal__fade_in}`)

	const [appliedFiltersMobile, setAppliedFiltersMobile] = useState(
		appliedFilters ?? []
	)

	const [isSmallScreen, setIsSmallScreen] = useState(false)

	useEffect(() => {
		const handleResizeMobile = () => {
			if (window.screen.width <= 540) {
				setIsSmallScreen(true)
			} else {
				setIsSmallScreen(false)
			}
		}
		handleResizeMobile()
	}, [isSmallScreen])

	useEffect(() => {
		if (!isMobile) {
			handleApplyFilters(null)
		}
	}, [sortBy])

	const handleApplyFilters = e => {
		e?.preventDefault()

		if (e) {
			setAppliedFilters([...appliedFiltersMobile])
			setAppliedSortBy(sortBy)
			setSearchedToken(searchByTokenIdMobile)
			onClose()
		} else {
			setAppliedFilters([...appliedFilters])
			setAppliedSortBy(sortBy)
			setSearchedToken("")
		}
	}

	const resetFiltersMobile = e => {
		e.preventDefault()

		setAppliedFiltersMobile([])
		setSearchByTokenIdMobile("")
		setSearchedToken("")
	}

	const resetFilters = e => {
		e.preventDefault()
		setAppliedFilters([])
		setAppliedSortBy(null)
		setSearchedToken("")
		setSearchByTokenIdMobile("")
	}

	const filterComponent = useMemo(() => {
		return (
			<FiltersView
				handleApplyFilters={handleApplyFilters}
				filters={filters}
				colors={colors}
				appliedColor={appliedColor}
				sortBy={sortBy}
				setSortBy={setSortBy}
				appliedFilters={appliedFilters}
				setAppliedFilters={setAppliedFilters}
				appliedFiltersMobile={appliedFiltersMobile}
				setAppliedFiltersMobile={setAppliedFiltersMobile}
				isMobile={isMobile}
				onChangeSearchByTokenId={onChangeSearchByTokenId}
				setSearchedToken={setSearchedToken}
				searchedToken={searchedToken}
				setSearchByTokenIdMobile={setSearchByTokenIdMobile}
				searchByTokenIdMobile={searchByTokenIdMobile}
				resetFilters={resetFilters}
				isMarketplace={isMarketplace}
			/>
		)
	}, [
		filters,
		colors,
		appliedColor,
		appliedFilters,
		setAppliedFilters,
		appliedFiltersMobile,
		searchedToken,
		searchByTokenIdMobile
	])

	return (
		<>
			{isMobile && (
				<div className={s.mobile_filter_header}>
					<div>Fashion Index</div>
				</div>
			)}
			{filterComponent}
			{isMobile && (
				<div className={s.mobile_apply_container}>
					<div className={s.mobile_apply_sub_container}>
						<button className={s.apply_buttons} onClick={resetFiltersMobile}>
							Reset
						</button>
						<button
							className={`${s.apply_buttons} ${s.white}`}
							onClick={handleApplyFilters}
						>
							Apply
						</button>
					</div>
					{isSmallScreen && (
						<div
							style={{ textAlign: "center", marginTop: 10 }}
							onClick={handleFilterCollapse}
						>
							<Image
								className={s.collapse_button}
								src={`${`/static/img/homepage/toggle_black_close_icon.svg`}`}
								priority={true}
								width={22}
								height={22}
								quality={100}
								alt={"Collapse Filter"}
							/>
						</div>
					)}
				</div>
			)}
		</>
	)
}
export default ModalFilters
